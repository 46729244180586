import querystring from "querystring";
import api from "./api";
import authHeader from "@/helpers/auth-header";

const moduleName = "subtest";

export default {
  index(params) {
    return api().get(`${moduleName}?${querystring.stringify(params)}`, {
      headers: authHeader()
    });
  },
  create(data) {
    return api().post(`${moduleName}/create`, data, {
      headers: authHeader()
    });
  },
  find(id) {
    return api().get(`${moduleName}/${id}/find`, {
      headers: authHeader()
    });
  },
  update(id, data) {
    return api().put(`${moduleName}/${id}/update`, data, {
      headers: authHeader()
    });
  },
  delete(id) {
    return api().delete(`${moduleName}/${id}/delete`, {
      headers: authHeader()
    });
  },
  search(query) {
    return api().get(`${moduleName}/search?query=${query}`, {
      headers: authHeader()
    });
  },
  findRunningSubtest(id) {
    return api().get(`${moduleName}/${id}/running`, {
      headers: authHeader()
    });
  },
  findRunningSubtestQuestions(id) {
    return api().get(`${moduleName}/${id}/running/questions`, {
      headers: authHeader()
    });
  },
  findQuestion(id, params) {
    return api().get(
      `${moduleName}/${id}/question?${querystring.stringify(params)}`,
      {
        headers: authHeader()
      }
    );
  },
  answerQuestion(id, data) {
    return api().post(`${moduleName}/${id}/answer-question`, data, {
      headers: authHeader()
    });
  },
  endTest(id) {
    return api().post(
      `${moduleName}/${id}/end-test`,
      {},
      {
        headers: authHeader()
      }
    );
  },
  runningSubtestById(runningSubtestId) {
    return api().get(`${moduleName}/${runningSubtestId}/running-subtest`, {
      headers: authHeader()
    });
  },
  testInformation(runningSubtestId) {
    return api().get(`${moduleName}/${runningSubtestId}/test-information`, {
      headers: authHeader()
    });
  },
  correctAnswer(runningSubtestId, questionId) {
    return api().get(
      `${moduleName}/${runningSubtestId}/${questionId}/correct-answer`,
      {
        headers: authHeader()
      }
    );
  }
};
